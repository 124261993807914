<template>
  <div :class="{'mobile': $vuetify.breakpoint.smAndDown}">
    <div class="subtitle-1 mb-1">
      {{ label }}
    </div>
    <slot name="default">
      <div 
        v-if="hasValue(value)"
        class="word-wrap"
      >
        {{ filteredValue }}
      </div>
      <div
        v-else
        class="value-or-default--none-text word-wrap"
      >
        -
      </div>
    </slot>
  </div>
</template>

<script>

export default {
  name: "ReadOnlyField",
  props: {
    "label": {
      type: String,
      default: ""
    },
    "value": {
      type: String,
      default: null
    },
    filter: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isIE: document.documentMode != null
    }
  },
  computed: {
    filteredValue() {
      if (this.filter) {
        const filter = this.$options.filters[this.filter]
        if (filter) {
          return filter(this.value);
        }
      }
      return this.value;
    }
  },
  methods: {
    hasValue(v) {
      return  v != null && v.trim() !== '';
    }
  }
}
</script>

<style lang="scss" scoped>
  .value-or-default--none-text {
    color: $ads-light-40;
  }
  /* IE long string with no-space wrap fix :( */
  .word-wrap {
    word-wrap: break-word;
  }
  .mobile .word-wrap {
    max-width: 90vw;
  }
</style>