<template>
  <div
    class="iconContainer d-flex align-center justify-center"
    :style="{
      width: size + 'px',
      height: size + 'px',
      border: this.transparent ? '1px solid white' : 'none'
    }"
  >
    <v-icon>{{ icon }}</v-icon>
  </div>
</template>

<script>

export default {
  name: "AppIconRole",
  props: {
    roleCode: {
      type: String,
      default: ""
    },
    transparent: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 40
    }},
  data() {
    return {};
  },
  computed: {
    icon() {
      const roleIconMap = {
        teacher: "mdi-school-outline",
        admin: "mdi-account-multiple-outline",
        custom: "mdi-cog-outline"
      }

      return roleIconMap[this.roleCode ? this.roleCode.toLowerCase() : ""] || "mdi-cog-outline";
    }
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
  .iconContainer {
    background: $ads-light-10;
    border-radius: 0.2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14);
    .v-icon {
      color: $ads-navy;
    }
  }

</style>
