<template>
  <Pill
    v-bind="pillColours"
    :label="pillLabel"
  />
</template>

<script>
import colours from "@/settings/doe-colors";
import Pill from "@/components/Pill";

export default {
  name: "RequestStatusPill",
  props: {
    approvalStatus: {
      type: String,
      required: true
    }
  },
  components: {
    Pill
  },
  computed: {
    pillColours() {
      const colourMap = {
        P: { color: colours.white, background: colours.warningOrange },
        R: { color: colours.white, background: colours.errorRed },
        F: { color: colours.white, background: colours.errorRed },
        A: { color: colours.white, background: colours.successGreen },
        S: { color: colours.white, background: colours.successGreen },
        E: { color: colours.white, background: colours.warningOrange },
        default: { color: colours.white, background: colours.dark }
      };
      const code = this.approvalStatus;
      const c = (code || "").toUpperCase();
      return colourMap[c] || colourMap.default;
    },
    pillLabel() {
      const labelMap = {
        P: "PENDING",
        R: "DENIED",
        A: "APPROVED",
        S: "APPROVED",
        E: "ESCALATED",
        L: "EXPIRED",
        F: "FAILED"
      };
      return labelMap[this.approvalStatus] || "UNKNOWN";
    }
  }
}
</script>