<template>
  <div
    class="pill subtitle-2"
    :style="{
      'color': color,
      'background-color': background
    }"
  >
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    background: {
      type: String,
      default: '#041E42'
    },
    color: {
      type: String,
      default: '#FFFFFF'
    }
  }
}
</script>

<style lang="scss" scoped>
.pill {
  border-radius: 1.1rem;
  height: 2.2rem;
  width: 7.5rem;
  max-width: 100%;
  text-transform: uppercase;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
