<template>
  <div
    class="email-landing--container"
    :class="{mobile: $vuetify.breakpoint.smAndDown}"
  >
    <div class="email-landing--content d-flex justify-center">
      <v-card
        v-if="loaded"
        class="py-9 email-landing--card"
        :flat="$vuetify.breakpoint.smAndDown"
      >
        <div class="d-flex flex-column align-center">
          <CircleIcon
            v-if="approved"
            icon="mdi-check"
            background-color="#C8DCF0"
            color="#00A908"
            size="185px"
            icon-size="125px"
          />
          <CircleIcon
            v-if="!approved"
            icon="mdi-close"
            background-color="#C8DCF0"
            color="#CE0037"
            size="185px"
            icon-size="125px"
          />
          <div class="mt-10 px-6 mx-md-6 text-center">
            <span class="headline"><b>{{ actionStatusTitle }}</b></span>
          </div>
        </div>

        <v-row
          class="mt-3 px-6 px-md-9 mx-md-6"
          v-if="request && request.approvalTimestamp && !actionProcessed"
        >
          <v-col
            cols="12"
            md="6"
          >
            <ReadOnlyField
              label="Approval status"
            >
              <RequestStatusPill :approval-status="request.approvalStatus" />
            </ReadOnlyField>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <ReadOnlyField
              label="Approver"
              :value="request.approverFirstName + ' ' + request.approverLastName"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <ReadOnlyField
              label="Actioned at"
              :value="request.approvalTimestamp"
              filter="timeDate"
            />
          </v-col>
        </v-row>

        <v-divider class="mt-7" />

        <div
          class="my-3 px-6 px-md-12 mx-md-6"
          v-if="request && request.staff"
        >
          <v-row class="d-flex align-center">
            <v-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-center">
                <InitialsIcon
                  :given-name="request.requestorFirstName"
                  :surname="request.requestorLastName"
                  class="mr-3"
                />
                <div>
                  <span>{{ request.requestorFirstName + ' ' + request.requestorLastName | capitalise }}</span>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-center">
                <AppIcon
                  v-if="isApp"
                  :app-name="request.application.icon"
                  class="app-icon--ie-fix"
                />
                <AppIconRole
                  v-if="!isApp"
                  :role-code="request.roleCode"
                />
                <div class="ml-3">
                  <span>{{ request.requestDescription }}</span>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <ReadOnlyField
                label="Access requested"
                :value="request.accessRequested"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <ReadOnlyField
                label="Location"
                :value="request.locationName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ReadOnlyField
                label="Comment"
                :value="request.requestorComments"
              />
            </v-col>
          </v-row>
        </div>

        <v-divider />

        <div class="d-flex justify-center mt-6">
          <v-btn
            class="primary btn-cta"
            to="/"
          >
            Go to MSA
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { InitialsIcon, AppIcon } from '@nswdoe/doe-ui-core';
import AppIconRole from "@/components/AppIconRole";
import CircleIcon from '@/components/CircleIcon'
import ReadOnlyField from "@/components/ReadOnlyField";
import RequestStatusPill from "@/components/RequestStatusPill";
import { mapGetters } from "vuex";

export default {
  name: "EmailActionApprove",
  components: {
    AppIcon,
    InitialsIcon,
    AppIconRole,
    CircleIcon,
    ReadOnlyField,
    RequestStatusPill
  },
  data() {
    return {
      loaded: false,
      requestId: parseInt(this.getQueryParam('id')),
      locationId: parseInt(this.getQueryParam('locationId')),
      request: {},
      approved: false,
      actionProcessed: false, // flag to tell if this instance completed the action (vs being actionProcessed before)
      actionStatusTitle: ""
    }
  },
  methods: {
    getQueryParam(name) {
      // get a query param value case-insensitively
      for (const p in this.$route.query) {
        if (p.toLowerCase() === name.toLowerCase()) {
          return this.$route.query[p];
        }
      }
      return '';
    }
  },
  async created() {
    let loadedRequests = this.requestsByLocation(this.locationId);

    if (!loadedRequests || !loadedRequests.length) {
      // load requests for location
      await this.$store.dispatch('fetchRequestHistory', this.locationId);
    }

    loadedRequests = this.requestsByLocation(this.locationId);
    this.request = loadedRequests.find(r => r.approvalId === this.requestId);

    if (this.request) {
      const approvalStatus = (this.request.approvalStatus || '').toLowerCase();
      if (approvalStatus === 'p' || approvalStatus === 'e') {
        // pending or escalated request found - attempt to approve.
        const data = {
          locationId: this.locationId,
          requestId: this.requestId,
          comment: '',
          approve: true,
          approverId: this.$OAuth.data.profile.short_name,
          approverDetUserId: this.$OAuth.data.profile.uid
        }

        await this.$store.dispatch('actionRequest', data)
          .then(() => {
            this.actionProcessed = true;
            this.approved = true;
            this.actionStatusTitle = "Request approved";
          })
          .catch(() => {
            this.approved = false;
            this.actionStatusTitle = "Could not complete approval. Please try again later.";
          });
      } else {
        // approval already actioned
        this.approved = true;
        this.actionStatusTitle = "Request already actioned";
      }
    } else {
      // request not found
      this.approved = false;
      this.actionStatusTitle = "Access request was not found";
    }

    this.loaded = true;
  },
  computed: {
    ...mapGetters({
      requestsByLocation: 'requestsByLocation'
    }),
    isApp() {
      return this.request && this.request.application != null;
    }
  }
}
</script>

<style lang="scss" scoped>
  .email-landing--container.mobile {
    padding: 0;
    .email-landing--card {
      min-width: 100%;
    }
  }
  .email-landing--container {
    padding-top: 100px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .email-landing--card {
    width: 650px;
  }
  .icon {
    color: #041E42;
  }
  .btn-cta {
    width: 200px;
  }
  .app-icon--ie-fix {
    // fix sizing issue in IE when adjacent name is long.
    flex: 1 0 auto;
  }
</style>
